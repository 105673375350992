.Schedule .Schedule__day {
    font-family: "Myriad Pro Condensed", "Arial Narrow", sans-serif;
}

.Schedule .Schedule__time h3 {
    font-family: "Myriad Pro Condensed", "Arial Narrow", sans-serif;
    font-size: 1.25rem;
    margin-bottom: 0;
}

.Schedule .Schedule__item {
    font-family: "Myriad Pro Condensed", "Arial Narrow", sans-serif;
}

.Schedule .Schedule__item .duration {
    color: #231F20;
    float: right;
    font-weight: bold;
}

.Schedule .Schedule__item .card-title {
    color: #231F20;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

.Schedule .Schedule__item .preamble {
    color: #231F20;
    font-style: italic;
    margin-bottom: 0.4rem;
}

.Schedule .Schedule__item .room {
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
}
