.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.App-content {
    flex: 1;
    text-align: center;
}

.error {
    color: #BF0000;
}

.success {
    color: #00AF00;
}

.text-black-20 {
    color: rgba(0, 0, 0, 0.2) !important;
}

.navbar {
    background-color: #000000;
}

button.btn-image {
    padding: 0.125rem 0.5rem;
}

button.btn-image img {
    margin: 0;
    height: 1.5rem;
}

.half-opacity {
    opacity: 0.5;
}
